import React, { Fragment } from "react";

 import logo from './logoW.png';
 import logoIn from './linkedinlogo.png';
import './App.css';

function App() {
  return (

    <div className="App">
      <header className="App-header">
      <h3>
          Welcome to Weking.
        </h3>
        <img src={logo} className="App-logo" alt="logo" />
        <a target="_blank" href="https://www.linkedin.com/company/weking" rel="noopener noreferrer">
                  <i className="fa fa-linkedin"></i>
                </a>
      </header>
    </div>
  );
}

export default App;
